<template>
  <!-- REDO BANNER -->
  <section class="banner-pattern">
    <div class="p-12 sm:p-20 md:p-36 lg:p-48 xl:p-60 banner">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div class="find-text md:py-0">
          <div class="w-full md:w-3/4">
            <div class="mb-4 text-4xl font-bold text-white">
              Tools & Resources
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Self Help tools -->
  <section class="pt-8 my-8 thoughts-sect md:my-12 gradient-white">
    <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Self-help tools
      </div>
      <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
        <router-link :to="{ name: 'NameYourEmotions' }">
          <QuickAccess
            arrow="text-white-100"
            text="Name your emotions"
            img="name-your-emotions.svg"
          />
        </router-link>
        <router-link :to="{ name: 'ChallengeYourThoughts' }">
          <QuickAccess
            arrow="text-green"
            text="Challenge your thoughts"
            img="challenge-your-thoughts.svg"
          />
        </router-link>
        <router-link :to="{ name: 'PracticeGuidedMeditation' }">
          <QuickAccess
            arrow="text-green"
            text="Practice guided meditation"
            img="meditation.svg"
          />
        </router-link>
        <router-link :to="{ name: 'PracticeBreathingExercises' }">
          <QuickAccess
            arrow="text-red"
            text="Practice breathing exercises"
            img="exercises.svg"
          />
        </router-link>
        <router-link :to="{ name: 'PracticeGroundingTechniques' }">
          <QuickAccess
            arrow="text-yellow"
            text="Practice grounding techniques  "
            img="practice-grounding-techniques.svg"
          />
        </router-link>
        <router-link :to="{ name: 'ExploreCulturalHealingPractices' }">
          <QuickAccess
            arrow="text-yellow"
            text="Explore cultural healing practices  "
            img="explore-cultural-healing-practices.svg"
          />
        </router-link>
        <router-link :to="{ name: 'CreateASafetyPlan' }">
          <QuickAccess
            arrow="text-white-100"
            text="Create a safety plan"
            img="create-a-safety-plan.svg"
          />
        </router-link>
        <router-link :to="{ name: 'WatchVideosAndPodcasts' }">
          <QuickAccess
            arrow="text-green"
            text="Watch and listen to videos, music and more"
            img="watch-videos-and-podcasts.svg"
          />
        </router-link>
        <router-link :to="{ name: 'MakeAHopeBox' }">
          <QuickAccess
            arrow="text-red"
            text="Make a hope box"
            img="make-a-hope-box.svg"
          />
        </router-link>
      </div>
    </div>
  </section>
  <!-- How to reach out for help -->
  <section class="my-8 thoughts-sect md:my-12 pt-14 gradient-white">
    <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        How to reach out for help
      </div>
      <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
        <router-link :to="{ name: 'LearnHowToAskForHelp' }">
          <QuickAccess
            arrow="text-white-100"
            text="Learn how to ask for help"
            img="learn-how-to-ask-for-help.svg"
          />
        </router-link>
        <router-link :to="{ name: 'CallACrisisHotline' }">
          <QuickAccess
            arrow="text-green"
            text="Call a crisis hotline"
            img="call-a-crisis-hotline.svg"
          />
        </router-link>

        <router-link :to="{ name: 'FindASupportGroup' }">
          <QuickAccess
            arrow="text-red"
            text="Find a support group"
            img="find-a-support-group.svg"
          />
        </router-link>
        <router-link :to="{ name: 'FindACounselor' }">
          <QuickAccess
            arrow="text-yellow"
            text="Find a counselor"
            img="find-a-counselor.svg"
          />
        </router-link>
      </div>
    </div>
  </section>

  <!-- QUOTES -->
  <Quote>
    <template v-slot:text>
      For a long-time life was so challenging and I felt like giving up. I am
      glad my friend helped me to ask for help.</template
    >
    <template v-slot:sign> JH </template>
  </Quote>
  <Quote>
    <template v-slot:text
      >I was getting bullied at school and then I started wishing I was dead. I
      spoke to someone at the distress center, and they helped me.</template
    >
    <template v-slot:sign>MP </template>
  </Quote>
</template>

<script>
import QuickAccess from "@/components/QuickAccess.vue";
import Quote from "@/components/Quote.vue";
export default {
  name: "ToolsAndResources",
  components: {
    QuickAccess,
    Quote,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("../../assets/images/tools-banner.png");
}

.banner-pattern::after {
  content: "";
  min-height: 30px;
  display: block;
  background-repeat: repeat-x;
  background-size: contain;
  background-image: url("../../assets/images/tools-banner-pattern.png");
}
</style>
